* {
  box-sizing: border-box;
}

html, body {
  background: #FFF;
  margin: 0;
  padding: 0;
  color: #121212;
  font: 16px 'Segoe UI', Helvetica, Arial, sans-serif;
  font-weight: 200;
  letter-spacing: 0.02em;

  @include respond-down(L) {
    font-size: 14px;
  }
}

html {
  scroll-behavior: smooth;
}

p {
  margin: 0 0 0.5em 0;
  line-height: 1.4em;
}

ul, ol {
  line-height: 1.4em;
  padding-inline-start: 1.1em;

  ol {
    padding-top: 4px;
    padding-inline-start: 1.8em;
    font-size: 96%;
  }

  li {
    margin-bottom: 0.4em;
  }
}

thead {
  background: #eee;
}

code {
  background-color: #eee;
  border-radius: 3px;
  font-size: 85%;
  margin: 0;
  padding: .2em .4em;
}

.lead {
  font-size: 1.1em;
  border-left: 8px solid #eee;
  padding-left: 12px;
  margin-bottom: 1.8em;
}

table {
  width: 100%;
  border-collapse: collapse;
  background: #eeeeee2e;
  margin-bottom: 16px;

  th {
    color: #333;
    font-weight: 400;
  }

  tr {
    border: 1px solid #d6d6d6;

    td, th {
      text-align: left;
      border: 1px solid #d9d9d9;
      padding: 6px 8px;
      min-width: 120px;

      @include respond-down(L) {
        min-width: 100px;
      }
    }
  }
}

.container {
  position: relative;
  z-index: 20;
  max-width: 1180px;
  margin: auto;
  padding: 0 20px;
  overflow: hidden;

  @include respond-down(L) {
    max-width: 887px;
  }
  @include respond-down(M) {
    padding: 0 12px;
    max-width: 100%;
  }
}

.h1,.h2,.h4 {
  margin-top: 0;
  line-height: 1.2em;
  font-weight: 100;
}

.part {
  margin-bottom: 30px;
  .items {
    margin-bottom: 18px;
  }
}

.dataset-group {
  //margin-right: 20px;
  table {
    width: auto;
    td.data {
      vertical-align: top !important;
    }
  }
}

.h1 {
  font-size: 2.0em;
  padding-top: 0.5em;
  margin-bottom: 0.5em;

  @include respond-down(L) {
    font-size: 1.8em;
  }

  a {
    text-decoration: none;
    color: #121212;
    padding: 5px 0;
  }
}

.h2 {
  font-size: 1.6em;
  display: flex;
  align-items: center;

  @include respond-down(L) {
    font-size: 1.4em;
  }

  .button {
    margin-right: 10px;
  }

  .group {
    margin-left: 20px;
    //margin-top: -4px;

    svg {
      margin-right: 5px;
    }

    &.tabs {
      .button {
        margin-right: -1px;
        border-radius: 0;
        position: relative;

        &:first-child {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }
        &:last-child {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }
        &.active {
          z-index: 10;
        }
        &:hover {
          z-index: 20;
        }
      }
    }
  }

}

.h4 {
  margin-bottom: 0.6em;
  font-weight: 400;
  font-size: 1.1em;
  @include respond-down(L) {
    font-size: 1em;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;

  .h1 {
    a {
      display: flex;
      align-items: center;
    }
    .logo {
      height: 40px;
      margin-right: 10px;
    }
    strong {
      display: block;
      font-weight: normal;
    }
    small {
      font-size: 14px;
      line-height: 1.4em;
    }
  }

  .nav {
    .link {
      padding: 5px 10px;
      text-decoration: none;
      color: #111;
      font-size: 18px;
      transition: color 0.25s ease;

      @include respond-down(M) {
        font-size: 15px;
      }

      span {
        position: relative;

        &:after {
          width: 0px;
          height: 1px;
          background: #222;
          transition: width 0.35s ease;
          position: absolute;
          content: '';
          bottom: -3px;
          left: 0;
        }
      }

      &:hover, &.active {
        color: #555;

        span:after {
          width: 100%;
        }
      }
    }
  }
}

.tables {
  display: flex;
  justify-content: space-between;
  margin-left: -12px;
  margin-right: -12px;

  @include respond-down(M) {
    flex-direction: column;
  }

  .col {
    flex: 1 1 100%;
    margin: 0 12px;

    @include respond-down(M) {
      &:not(:last-child) {
        margin-bottom: 18px;
      }
    }

    .h4 {
      margin-bottom: 0.4em;
    }
  }
}

.space {
  margin-bottom: 30px;
}

.load-more {
  padding: 20px;
  text-align: center;
  width: 100%;
  font-size: 14px;
}

.items {
  display: flex;
  flex-wrap: wrap;

  div {
    border-radius: 4px;
    margin: 0 10px 8px 0;
  }
}

.tabs {
  display: flex;
  align-items: flex-end;

  .tab {
    padding: 10px 20px;
    border-radius: 3px 3px 0 0;
    font-weight: bold;
    transition: border-top-color 0.25s ease-in-out,
      border-right-color 0.25s ease-in-out,
      border-left-color 0.25s ease-in-out,
      color 0.25s ease-in-out;

    &:not(.current) {
      color: rgba(0,0,0,0.6);
      cursor: pointer;
      border-bottom: 1px solid #b3b3b3;
    }
    &.current {
      margin: 0 -1px;
      border: 1px solid #b3b3b3;
      border-bottom: 1px solid transparent;
    }
  }
  .spacer {
    flex: 1 1 100%;
    border-bottom: 1px solid #b3b3b3;
  }
}

.bottom {
  border-top: 1px solid #eee;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: #888;

  @include respond-down(M) {
    min-height: 40px;
  }
}

.code {
  white-space: pre;
  font-family: monospace;
  font-size: 14px;
  padding: 8px 12px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-bottom: 18px;
}

.model-items {
  padding-top: 20px;
  margin-bottom: 18px;

  .model-item {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    .img-type {
      height: 356px;
    }

    .m-clusters {

      .cluster {
        height: 146px;
        display: flex;
        padding: 8px;
        border: 3px solid #e8e8e8;
        border-radius: 6px;
        margin: 5px 0;
        position: relative;

        &.empty {
          align-items: center;
          justify-content: center;
          font-size: 14px;
          color: #d7d7d7;
          font-weight: bold;
          letter-spacing: 0.05em;
        }

        .part {
          position: absolute;
          border: 3px solid #e8e8e8;
          top: -3px;
          left: -3px;
          background: rgba(#fff,0.7);
          border-radius: 6px 0 6px 0;
        }

        .img-wrap {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          .button {
            position: absolute;
            font-size: 12px;
            opacity: 0;
            transition: opacity 0.15s ease;
          }
        }

        .img {
          height: 124px;
        }

        &:hover {
          .img-wrap {
            .button {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.error-page {
  font-size: 20px;
  font-weight: 100;
  min-height: 200px;
  color: #444;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}