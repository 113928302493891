
.controls {
  padding-bottom: 14px;
  background: #FFF;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 0 !important;

  .group {
    margin-right: 8px;

    &.filters {
      @include respond-down(M) {
        margin: 0;
        padding-top: 12px;
      }
    }

    .label {
      margin-bottom: 5px;
      font-size: 12px;
      line-height: 1em;
      display: block;
    }
  }
}

.karyotype-box {
  padding-top: 20px;
  margin-bottom: 20px;
}

.form-control {
  width: 128px;
  height: 26px;
  display: block;
  padding: 4px 6px;
  font-size: 14px;
  line-height: 1.2em;
  color: #555;
  background-color: #fff;
  border: 1px solid rgb(173, 173, 173);
  border-radius: 3px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;

  @include respond-down(M) {
    width: 110px;
  }

  &.small {
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.2em;
    border-radius: 0;
  }

  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
  }
}

.checkbox {
  height: 26px;
  padding: 4px 8px;
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin-right: -1px;
  cursor: pointer;
  position: relative;

  &:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  &:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .circle {
    margin-left: 5px;
  }

  @include respond-down(M) {
    padding-right: 5px;
    padding-left: 5px;
  }

  input {
    margin: 0 5px 1px 0;
  }
}

.button {
  font-size: 14px;
  text-decoration: none;
  height: 26px;
  padding: 4px 8px;
  border-radius: 3px;
  color: #212529;
  user-select: none;
  cursor: pointer;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  text-align: center;
  white-space: nowrap;
  background: #f5f5f5 linear-gradient(to bottom, #ffffff, #e6e6e6) repeat-x;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom-color: #b3b3b3;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);

  &.selector {
    padding: 4px 14px 4px 8px;
    text-align: left;

    @include respond-down(M) {
      padding: 4px 8px 4px 8px;
    }
  }

  &:active, &.active {
    user-select: none;
    z-index: 2;
    background: #e6e6e6;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  &:hover {
    //background-color: #e2e8ed;
    border: 1px solid rgb(70, 70, 70);
    z-index: 15;
  }
  &:active {
    //box-shadow: 0 0 0 2px rgba(0,0,0,0.2);
    //border: 1px solid rgb(20, 20, 20);
    z-index: 15;
  }

  svg {
    width: 14px;
    margin-right: 4px;
  }

  &.green {
    background: rgba(0, 239, 81, 0.36);
    border-color: rgba(0, 239, 81, 0.36);

    &:hover {
      background: rgba(0, 221, 81, 0.36);
      border-color: rgba(0, 197, 71, 0.36);
    }
    &:active {
      background-color: rgba(0, 148, 54, 0.52);
      border-color: rgba(0, 199, 68, 0.52);
    }
  }
}

.density {
  //fill-opacity: 0.75;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 12px;
  display: inline-block;
  background: #333;
}

.signals {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
  padding-top: 10px;

  @include respond-down(M) {
    margin: 0 -6px;
  }

  .placeholder {
    width: 100%;
    padding: 30px;
    text-align: center;
  }

  .signal-wrapper {
    position: relative;
    //width: 260px;
    //padding: 6px 5px 0 5px;
    margin: 14px 16px 20px 16px;

    @include respond-down(M) {
      margin: 12px 6px 16px 6px;
    }

    .axis--y {
      .tick:first-of-type {
        display: none;
      }
    }

    .hints {
      margin-right: -1px;
    }

    .sax-plot {
      line {
        stroke-width: 1px;
        stroke: #296f00;
      }
    }

    .mean-line {
      stroke-width: 1px;
      stroke: #02f;
    }
    .v-line {
      stroke-width: 1px;
      stroke: rgba(#ff2200, 0.5);
    }
  }
}

.histogram-wrapper {
  margin: -3px -5px;
  position: relative;

  .helper {
    font-size: 11px;
    text-align: center;
  }

  .col {
    margin: 0;
    padding: 0 2px;
    background: #eee;
    font-size: 11px;
    display: flex;

    &.head {
      margin-bottom: 2px;
      font-size: 12px;
    }

    &.footer {
      margin-top: 2px;
      font-size: 12px;
    }

    span {
      flex: 1 1 33.33%;

      &.side-R { color: #080; }
      &.side-L { color: #f00; }
      &.side-BP { color: #009; }
      &.side-spSV { color: #a60; }

      &.w66 {
        flex: 1 1 66.66%;
      }
    }
  }
}

.hist-plot-svg {
  margin: 4px 4px 24px 4px;
  overflow: visible;
  position: relative;
  display: block;
  //background: rgba(#e9bb66, 0.08);

  .coverage {
    &.side-R { fill: rgba(#080, 0.2); stroke: #080; }
    &.side-L { fill: rgba(#f00, 0.2); stroke: #f00; }
    &.side-BP { fill: rgba(#009, 0.2); stroke: #009; }
    &.side-spSV { fill: rgba(#a60, 0.2); stroke: #a60; }
  }
}

.signal-plot-svg {
  overflow: visible;
  position: relative;
  z-index: 10;
  width: 100%;
  display: block;
  background: rgba(#668be9, 0.08);

  .coverage {
    fill: rgba(#668be9, 0.3);
    stroke: #668be9;
  }
}

.annotation {
  border-left: 8px solid #eee;
  padding-left: 12px;
  font-size: 14px;

  .item {
    display: flex;
    align-items: center;
    margin-bottom: 7px;

    .tag {
      margin: 0 3px 0 0;
      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .a-box {
    margin-right: 10px;
    min-width: 20px;
    height: 20px;
    border: 1px solid #aaa;
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden;
    flex: 0 0 auto;
    position: relative;

    .sax-ex {
      width: 4px;
      height: 1px;
      background: #296f00;
      &:nth-child(1) { transform: translateY(-4px); width: 3px }
      &:nth-child(2) { transform: translateY(-2px) }
      &:nth-child(3) { transform: translateY(3px) }
      &:nth-child(4) { transform: translateY(1px) }
      &:nth-child(5) { transform: translateY(-4px); width: 3px }
    }

    &.t {
      border: none;
    }

    svg {
      overflow: hidden;
      position: absolute;
      left: -1px;
      top: -12px;
      width: 19px;
    }

    .vl {
      height: 18px;
      width: 1px;
      background: rgba(255,34,0,.5);
    }
    .hl {
      height: 1px;
      width: 18px;
      background: #02f;
    }
  }



}

.hints {
  z-index: 20;
  padding: 4px;
  margin-bottom: 5px;
  display: flex;
  border-radius: 3px 3px 0 0;
  border: 1px solid #aaa;
  justify-content: space-between;
  align-items: center;

  font-size: 12px;
  font-weight: bold;
  line-height: 1.2em;

  & > span {
    display: flex;
    align-items: center;
    padding: 0 4px;
  }
}

.tag {
  border-radius: 3px;
  padding: 2px 4px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.2em;

  &.mini { font-size: 11px; }
  &.side-R { box-shadow: inset 0 0 0 1px #080; background: rgba(#080, 0.1) }
  &.side-L { box-shadow: inset 0 0 0 1px #f00; background: rgba(#f00, 0.1) }
  &.side-BP { box-shadow: inset 0 0 0 1px #009; background: rgba(#009, 0.1) }
  &.side-spSV { box-shadow: inset 0 0 0 1px #a60; background: rgba(#a60, 0.1) }
}

.karyotype-svg {
  .chr-border {
    fill: transparent;
    stroke-width: 1px;
    stroke: #111;
  }
  line.karyo-hints {
    stroke: #000;
    stroke-width: 1px;
  }
  text.karyo-hints {
    font-size: 10px;
  }
  pattern line {
    stroke: #ff0000;
    stroke-width: 0.7px;
  }
  rect.selection {
    stroke: #A00;
    fill: #F00;
    stroke-width: 1px;
    transform: translate(0, -2px);
  }
}

.modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 200;
  align-content: center;
  justify-content: center;
  display: none;

  .bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 200;
    background: rgba(255,255,255,0.75);
  }

  .container {
    transition: transform 0.25s ease-in-out;
    transform: scale(0.85);
    width: 100%;
    position: relative;
    z-index: 300;

    img {
      display: block;
      margin: 0;
    }
  }

  .modal-head {
    display: flex;
    align-content: center;
    justify-content: space-between;
    background: #fff;
    border-radius: 5px 5px 0 0;
    border: 1px solid #aaa;
    border-bottom: none;

    .modal-title {
      font-size: 20px;
      font-weight: bold;
      padding: 10px 0 10px 10px;
    }

    .modal-close {
      padding: 10px;
      cursor: pointer;

      span {
        display: flex;
        align-content: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border: 1px solid #222;
        color: #222;
        border-radius: 24px;
        padding-top: 1px;
        font-size: 18px;
        line-height: 18px;
        transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      }

      &:hover {
        span {
          border: 1px solid #a00;
          color: #a00;
        }
      }
    }
  }

  &.visible {
    display: flex;

    .container {
      transform: scale(1);
    }
  }
}

.model-hmm {
  background: #fff;
  border: 1px solid #aaa;

  img {
    width: 100%;
  }
}

